import React, { useState, useEffect } from "react";
import fiec from "../../assets/Images/fiec_navbar.png";
import routes from "../../assets/json/routes.json";
import { useLocation } from "react-router-dom";
import { Nav, NavLink, Bars, NavMenu, NavBtn, NavBtnLink, Img } from "./styles";
import { isAuthenticatedDev } from "../../auth";

export default function Navbar() {
  const [show, setShow] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShow(
      routes.includes(location.pathname) ||
        location.pathname.startsWith("/coleta/")
    );
  }, [location.pathname]);

  return (
    <>
      <Nav show={show}>
        <NavLink to="/home">
          <Img src={fiec} alt="logo" width="100" height="29.07px" />
        </NavLink>
        <Bars />
        <NavMenu>
          <NavLink to="/home"> Home </NavLink>
          <NavLink to="/alunos"> Alunos </NavLink>
          <NavLink to="/consultar"> Consultar </NavLink>
          <NavLink to="/importar"> Importar </NavLink>
          <NavLink to="/configurar"> Configurar </NavLink>
          <NavLink to="/todosalunospdf"> Gerar PDF alunos </NavLink>
          {/* {!isAuthenticatedDev() || (
            <NavLink to="/cadastrar"> Cadastrar </NavLink>
          )} */}
        </NavMenu>
        <NavBtn>
          <NavBtnLink onClick={() => localStorage.clear()} to="/">
            Sair
          </NavBtnLink>
        </NavBtn>
      </Nav>
    </>
  );
}
