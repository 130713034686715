import axios from "axios";
import Texts from "../assets/json/texts.json";

const api = axios.create({
  baseURL: Texts.STATIC_URL_PROD,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
