import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import api from "../../services/api";
import Upload from "./UploadImage";
import iconxlsx from "../../assets/Images/xls.png";
import { Container, Title, Card, Form, Button, IconContainer } from "./style";
import { FiTrash2 } from "react-icons/fi";

export default function Importar() {
  const [foto, setFoto] = useState([]);
  const [haveImage, setHaveImage] = useState(false);
  const [url_foto, setUrl_foto] = useState(null);

  useEffect(() => {
    foto.map((file) => setUrl_foto(URL.createObjectURL(file)));
    if (foto.length) {
      setHaveImage(true);
    } else {
      setHaveImage(false);
    }
  }, [foto]);

  const submitFile = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    if (foto.length)
      foto.forEach((file) => formData.append(`planilha_coletas`, file));

    try {
      Swal.fire({
        title: "Aguarde um momento",
        icon: "info",
        confirmButtonColor: "var(--blue-color)",
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const response = await api.post(`/coletas/xlsx`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
        },
      });
      if (response.data.error) {
        return Swal.fire({
          title: "Atenção!",
          text: response.data.error,
          icon: "warning",
          didOpen: () => {
            Swal.hideLoading();
          },
        });
      }
      Swal.close();
      Swal.fire({
        title: "Sucesso!",
        text: response.data.success,
        icon: "success",
      }).then(() => {
        setFoto([]);
      });
    } catch (error) {
      console.error(error);
      Swal("Atenção!", "Serviço indisponível no momento", "error");
    }
  };

  return (
    <Container>
      <Title>Inserir Planilha</Title>
      <Card>
        <Form onSubmit={submitFile}>
          {haveImage ? (
            <IconContainer>
              <img
                src={iconxlsx}
                alt="Erro"
                title={foto.length && foto[0].name}
              />
              <FiTrash2 className="trash-icon" onClick={() => setFoto([])} />
            </IconContainer>
          ) : (
            <Upload amount={1} msg={`( Planilha ) `} setFile={setFoto} />
          )}
          <Button disabled={!haveImage}>Importar</Button>
        </Form>
      </Card>
    </Container>
  );
}
