import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { useParams } from "react-router";
import ReturnArrow from "../../components/ReturnArrow";
import { Background, Container, Field, Label, Value, Title } from "./style";

export default function Coleta() {
  const { rm } = useParams();
  const [Coleta, setColeta] = useState("");

  useEffect(() => {
    api
      .get(`/coletas/${rm}`)
      .then((res) => {
        setColeta(res.data.coleta);
      })
      .catch((e) => console.log(e));
  }, [rm]);

  // useEffect(() => {
  //   if (Coleta) {
  //     // console.log(Coleta);
  //     Object.entries(Coleta).map(([c, k]) => {
  //       console.log(k); // "a 5", "b 7", "c 9"
  //     });
  //     // console.log(Coleta.values());
  //   }
  // }, [Coleta]);

  return (
    <Background>
      <ReturnArrow />
      <Title>Dados de Coleta</Title>
      <Container>
        {Object.entries(Coleta).map(([k, c]) => (
          <Field>
            <Label>
              {k
                .replaceAll("_", " ")
                .replace(/(^\w{1})|(\s+\w{1})/g, (letra) =>
                  letra.toUpperCase()
                )}
            </Label>
            <Value>{c}</Value>
          </Field>
        ))}
      </Container>
    </Background>
  );
}
