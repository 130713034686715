import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;

    margin: 15px auto 0 auto;
    width: 40%;
    padding: 10px;

    border: 1px solid black; 
    border-radius: 15px;
    @media screen and (max-width: 1320px) { /* Tablet styles  */
        width: 60%;
    }

    @media screen and (max-width: 790px) { /* Mobile styles  */
        width: 80%;
    }
`;

export const ConfigInput = styled.input.attrs({ type: "number" })`
    text-align: center;
    width: 150px;
    height: 35px;
    margin: 10px 15px;  

    background: transparent;
    color: var(--black-color);

    border-radius: 25px;
    border: 3px solid #ccc; 
    outline: none;
`;

export const ConfigTable = styled.div`
    table{
        margin-left: auto;
        margin-right: auto;
    }
    th{
        text-align: center;
        font-size: 1.2rem;
        font-weight: 100;
    }
    tr{
        margin-left: 30px;
    }
    
    border-radius: 10px;
    border: 1px solid black;
    padding: 15px; 
`

export const ConfigForm = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 30px;  
`;

export const ConfigSelect = styled.div`
    select{
        width: 140px;
        text-align: center;
        width: 150px;
        height: 35px;
        background: transparent;
        color: var(--black-color);
        border-radius: 25px;
        border: 3px solid #ccc;
        outline: none;
        margin-right: 15px;
        margin-right: 110px;
    }

    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`;

export const ConfigSelectTop = styled.div`
    select{
        width: 300px;
        text-align: center;
        height: 35px;
        background: transparent;
        color: var(--black-color);
        border-radius: 25px;
        border: 3px solid #ccc;
        outline: none;
        margin-right: 15px;
    }
`;

export const LabelColumn = styled.div`
    display: grid;
    grid-template-areas: "."
        "."
        "."
        "."
        "."
        ".";
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 20px 30px;

    label {
        margin:0 0 0 0;
        font-size: 1.2rem;
        align-self: center;
      
    }

    @media screen and (max-width: 1320px) { /* Tablet styles  */
    
    }

    @media screen and (max-width: 790px) { /* Mobile styles  */
        
    }
`;

export const LabelRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Button = styled.div`
    button {
        width: 150px;

        margin: 10px 15px;
        padding: 10px 22px;

        background: var(--secundary-color);
        color: var(--white-color);

        border-radius: 25px;
        border: none;
        outline: none;
    }
`;


export const CheckBoxContainer = styled.div`

    display: flex;
    gap: 0px 10px;
    flex-direction: column;

    label {
        width: 100%;
        
    }
    
`;


export const CheckBox = styled.input`



`;

export const CheckBoxLabel = styled.div`


    display: flex;

`;

export const CheckBoxes = styled.div`

flex-direction: column;
    display: flex;
    padding-left: 50px;
    width: 50%;

`;