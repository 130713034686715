import React, { useState } from 'react'
import api from '../../services/api'

const SampleForm = () => {

    const INITIAL_STATE = {
        rm: null,
        nome: null,
        genero: null,
        data_nascimento: null,
        curso: null,
        periodo: null,
        data_cadastro: null
    }

    const [rm, setRm] = useState(null)
    const [student, setStudent] = useState(null)

    const getStudent = () => {
        api.get(`alunos/${rm}`)
        .then(res => setStudent(res.data[0]))
        .catch((err) => setStudent(err))
    }

    return (
        <div>
            <form>
                <label htmlFor="rm">Seu rm</label>
                <input type="text" onChange={setRm((e) => e.target.value)} />
                <span>{student}</span>
                <button onClick={(e) => getStudent}></button>
            </form>

        </div>
    )
}

export default SampleForm
