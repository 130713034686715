import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    :root{
        /* Cores globais, para usar chame assim: background-color: var(--background-color) */
        --background-color: #23232e;
        --black-color: #08090A;
        --white-color: white;

        --primary-color: #1c9997;
        --secundary-color: #504b43;
        --auxiliar-color: #6f8f72;
        --table-color: #eeeeee;
        --gray-color: #eeeeee;
    }

    *{
        margin: 0;
        padding: 0;
        font-family: 'Source Sans Pro', sans-serif;
    }

    body{
        margin: 0;
        padding: 0;
    }

    button {
        transition: transform 0.5s;
        transition: box-shadow 0.2s;

        &:disabled {
            opacity: 0.5;
        }

        &:hover:enabled {
            box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
            transform: scale(1.05);
        }
    }
`;
