import React from "react";
import { Container, Button, Counter } from "./style";

export default function Pagination({ page, itemCount, setPage, itemsPerPage }) {
  const lastPage = Math.ceil(itemCount / itemsPerPage);

  return (
    <Container>
      <Button disabled={page === 0} onClick={() => setPage(page - 1)}>
        Anterior
      </Button>
      <Counter>
        {page + 1} / {lastPage}
      </Counter>
      <Button
        disabled={page === lastPage - 1}
        onClick={() => setPage(page + 1)}
      >
        Próximo
      </Button>
    </Container>
  );
}
