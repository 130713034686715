import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { HiChevronDown } from "react-icons/hi";

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
`;

export const SelectedFiltersContainer = styled.div`
  width: 90%;
  /* border: 1px solid red; */
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 50px 0;
`;

export const FiltersContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  min-height: 60px;
  background-color: var(--gray-color);
  align-items: center;
  flex-wrap: wrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Filter = styled.div`
  position: relative;
  height: 100%;
  width: 190px;
  cursor: pointer;
  outline: 1px solid white;
`;

export const FilterButton = styled.div`
  height: 60px;
  gap: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
`;

export const FilterBox = styled.div`
  position: absolute;
  display: ${(props) => !props.show && "none"};
  width: 100%;
  background-color: var(--gray-color);
`;

export const SubFilterBox = styled.div`
  display: ${(props) => !props.show && "none"};
  width: 100%;
  background-color: var(--gray-color);
`;

export const FilterOption = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
  border-color: var(--primary-color);
  color: ${(props) => (props.selected ? "var(--primary-color)" : "black")};
  padding: 0 10px;
  margin-left: 30px;

  &:before {
    display: ${(props) => !props.selected && "none"};
    content: "";
    background-color: var(--primary-color);
    width: 13px;
    height: 13px;
    position: absolute;
    left: 13px;
    border-radius: 10px;
  }
  &:after {
    content: "";
    border: 1px solid
      ${(props) => (props.selected ? "var(--primary-color)" : "black")};
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
    border-radius: 5px;
  }
`;

export const SelectedFilters = styled.div`
  border: 1px solid black;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
`;

export const Arrow = styled(HiChevronDown)`
  font-size: 20px;
  transition: transform 0.5s;
  transform: ${(props) => (props.open ? "rotate(180deg)" : "rotate(0)")};
`;

export const RemoveButton = styled(MdClose)`
  cursor: pointer;
`;

export const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  margin: 15px 200px;
  padding: 10px;

  border: 1px solid black;
  border-radius: 15px;

  #title {
    font-size: 1.7rem;
    margin-top: 20px;
    margin-bottom: -25px;
  }

  label {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    width: 60%;
  }

  @media screen and (max-width: 790px) {
    /* Mobile styles  */
    width: 80%;
  }
`;

export const Title = styled.h1``;

export const Date = styled.input.attrs({ type: "date" })`
  text-align: center;
  width: 150px;
  height: 35px;
  margin: 10px 15px;
  background: transparent;
  color: var(--black-color);
  border-radius: 25px;
  border: 3px solid #ccc;
  outline: none;
  ::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    text-align: center;
  }

  @media screen and (max-width: 790px) {
    /* Mobile styles  */
  }
`;

export const CheckboxContainer = styled.div`
  display: grid;
  grid-template-areas:
    ". . ."
    ". . .";
  justify-content: center;
  margin: 20px 30px;

  width: 60%;
  color: var(--black-color);
  font-size: 0.9em;

  label {
    flex-direction: row;
    margin: -5px 50px 0 0;
  }
  li {
    list-style: none;
  }

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    display: grid;
    grid-template-areas:
      "."
      "."
      "."
      "."
      "."
      ".";
    align-items: center;
  }
  @media screen and (max-width: 790px) {
    /* Mobile styles  */
  }
`;

export const CheckboxLabel = styled.div`
  display: flex;
  margin: 10px 0;
  width: 300px;
`;

export const Button = styled.button`
  width: 150px;

  margin: 10px 15px;
  padding: 10px 22px;

  background: var(--secundary-color);
  color: var(--white-color);

  border-radius: 25px;
  border: none;
  outline: none;

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    width: auto;
  }

  @media screen and (max-width: 790px) {
    /* Mobile styles  */
  }
`;

export const Secundario = styled.div`
  margin-left: 20px;
  width: 200px;
  display: ${(props) => (props.value ? "flex-box" : "none")};
`;

export const Table = styled.table`
  width: 90%;

  td:not(.aluno),
  th {
    text-align: center;
  }

  tbody tr {
    cursor: pointer;
  }
`;

export const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
`;
