import styled from "styled-components";
import fundo from "../../assets/Images/login.jpg";

export const Background = styled.div`
  display: grid;
  grid-template-areas: "i c";
  grid-template-columns: 1fr 500px;
  align-items: center;
  justify-items: center;
  width: 100vw;
  height: 100vh;
  background: var(--background-color);

  @media (max-width: 1200px) {
    grid-template-areas: "c c";
  }
`;

export const Img = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  grid-area: i;
  background-image: url(${fundo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1200px) {
    filter: blur(5px);
    -webkit-filter: blur(5px);
    position: fixed;
    z-index: 1;
    opacity: 0.5;
  }
`;

export const BigTitle = styled.h1``;

export const Form = styled.form`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  grid-area: c;
  align-items: center;
  width: 400px;
  max-height: 700px;
  background: var(--white-color);
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;

  @media screen and (max-width: 1200px) {
    z-index: 2;
  }
`;

export const Title = styled.h1`
  color: var(--font-color);
  line-height: 1.43;
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
`;

export const Input = styled.input`
  width: 65%;
  margin-bottom: 30px;
  padding: 0.5rem;
  background: none;
  color: var(--font-color);
  border: 2px solid var(--font-color);
  border-radius: 0.3rem;
  border-bottom: 2px solid #ccc;

  ::placeholder {
    color: var(--font-color);
  }

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const Button = styled.button`
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 20px;
  text-transform: uppercase;
  border: none;
  color: none;
  color: var(--white-color);
  background: var(--background-color);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
`;
