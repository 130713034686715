import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { isAuthenticated, isAuthenticatedDev } from "../src/auth";

import Navbar from "./components/Navbar";

// Rotas públicas
import Login from "./pages/Login";
import Home from "./pages/Home";
import Alunos from "./pages/Alunos";
import Registrar from "./pages/Registrar";
import Consultar from "./pages/Consultar";
import Importar from "./pages/Importar";
import Configurar from "./pages/Configurar";
import Cadastrar from "./pages/Cadastrar";
import SampleForm from "./pages/SampleForm";
import Coleta from "./pages/Coleta";
import PdfAluno from "./pages/PdfAluno";
import PdfTodosalunos from "./pages/PdfTodosAlunos";

export default function Rotas() {
  function PrivateRoute({ children }) {
    return isAuthenticated() ? children : <Navigate to="/" />;
  }

  function DevRoute({ children }) {
    return isAuthenticatedDev() ? children : <Navigate to="/" />;
  }

  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        {/* Rotas Públicas */}
        <Route path="/" element={<Login />} />
        <Route path="/formulario" element={<SampleForm />} />

        {/* Rotas Privadas */}
        <Route
          exact
          path="/home"
          element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/alunos"
          element={
            <PrivateRoute>
              <Alunos />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/alunos/:id"
          element={
            <PrivateRoute>
              <Alunos />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/registrar"
          element={
            <PrivateRoute>
              <Registrar />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/registrar/:id"
          element={
            <PrivateRoute>
              <Registrar />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/importar"
          element={
            <PrivateRoute>
              <Importar />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/consultar"
          element={
            <PrivateRoute>
              <Consultar />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/configurar"
          element={
            <PrivateRoute>
              <Configurar />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path="/coleta/:rm"
          element={
            <PrivateRoute>
              <Coleta />
            </PrivateRoute>
          }
        />

        <Route
          exact
          path="/alunopdf"
          element={
            <PrivateRoute>
              <PdfAluno />
            </PrivateRoute>
          }
        />
                <Route
          exact
          path="/todosalunospdf"
          element={
            <PrivateRoute>
              <PdfTodosalunos />
            </PrivateRoute>
          }
        />
        {/* Rotas desenvolvedor */}
        {/* <Route
          exact
          path="/cadastrar"
          element={
            <DevRoute>
              <Cadastrar />
            </DevRoute>
          }
        /> */}

        {/* Não Encontrado */}
        <Route path="*" element={<h1>Página não encontrada</h1>} />
      </Routes>
    </BrowserRouter>
  );
}
