import React, { useState, useEffect } from "react";
import {
  ConfigInput,
  Container,
  LabelColumn,
  ConfigForm,
  Button,
  ConfigTable,
  CheckBoxContainer,
  CheckBoxLabel,
  CheckBox,
  CheckBoxes
} from "./style";
import api from "../../services/api";

export default function Configurar() {
  const initialState = {
    id: "",
    tipo: "",
    descricao: "",
    minimo: "",
    maximo: "",
  };

  const [hipertensao, setHipertensao] = useState(initialState);
  const [pressaoNormal, setPressaoNormal] = useState(initialState);
  const [preHipertensao, setPreHipertensao] = useState(initialState);
  const [diabetico, setDiabetico] = useState(initialState);
  const [dextroNormal, setDextroNormal] = useState(initialState);
  const [faixaIdealMasc, setFaixaIdealMasc] = useState(initialState);
  const [riscoAumentadoMasc, setRiscoAumentadoMasc] = useState(initialState);
  const [riscoMtAumentadoMasc, setRiscoMtAumentadoMasc] =
    useState(initialState);
  const [faixaIdealFem, setFaixaIdealFem] = useState(initialState);
  const [riscoAumentadoFem, setRiscoAumentadoFem] = useState(initialState);
  const [riscoMtAumentadoFem, setRiscoMtAumentadoFem] = useState(initialState);
  const [pesoMtAbaixo, setPesoMtAbaixo] = useState(initialState);
  const [pesoAbaixo, setPesoAbaixo] = useState(initialState);
  const [pesoNormal, setPesoNormal] = useState(initialState);
  const [pesoAcima, setPesoAcima] = useState(initialState);
  const [obeso1, setObeso1] = useState(initialState);
  const [obeso2, setObeso2] = useState(initialState);
  const [obeso3, setObeso3] = useState(initialState);
  const [consumo, setConsumo] = useState(["Nunca/raramente", "1 vez por dia", "2 vezes por dia", "3 vezes ou mais por dia"]);
  const [consumo_de_carne, setConsumo_de_carne] = useState([]);
  const [consumo_de_leite, setConsumo_de_leite] = useState([]);
  const [consumo_de_oleo, setConsumo_de_oleo] = useState([]);
  const [consumo_de_petiscos, setConsumo_de_petiscos] = useState([]);
  const [consumo_de_cereais, setConsumo_de_cereais] = useState([]);
  const [consumo_de_legumes, setConsumo_de_legumes] = useState([]);
  const [consumo_de_hortalicas, setConsumo_de_hortalicas] = useState([]);
  const [consumo_de_sobremesas, setConsumo_de_sobremesas] = useState([]);
  const [consumo_de_bebidas, setConsumo_de_bebidas] = useState([]);
  const [consumo_de_agua, setConsumo_de_agua] = useState([]);
  const [labelsConsumo, setLabelsConsumo] = useState(
    ["Consumo de carne", "Consumo de leite", "Consumo de óleo",
    "Consumo de petiscos", "Consumo de cereais", "Consumo de legumes", 
    "Consumo de horataliças", "Consumo de sobremesas",
    "Consumo de bebidas", "Consumo de água"]);
  const [consumos, setConsumos] = useState("");





  const regras = [
    hipertensao,
    pressaoNormal,
    preHipertensao,
    diabetico,
    dextroNormal,
    faixaIdealMasc,
    riscoAumentadoMasc,
    riscoMtAumentadoMasc,
    faixaIdealFem,
    riscoAumentadoFem,
    riscoMtAumentadoFem,
    pesoMtAbaixo,
    pesoAbaixo,
    pesoNormal,
    pesoAcima,
    obeso1,
    obeso2,
    obeso3,
    consumo_de_carne,
    consumo_de_leite,
    consumo_de_oleo,
    consumo_de_petiscos,
    consumo_de_cereais,
    consumo_de_legumes,
    consumo_de_hortalicas,
    consumo_de_sobremesas,
    consumo_de_bebidas,
    consumo_de_agua,
        
  ];

  const getRegras = () => {
    api
      .get(`/regras`)
      .then((response) =>
        // eslint-disable-next-line array-callback-return
        response.data.regras.map((res) => {
          res.descricao !== "hipertensão" || setHipertensao(res);
          res.descricao !== "pressão normal" || setPressaoNormal(res);
          res.descricao !== "pré hipertensão" || setPreHipertensao(res);
          res.descricao !== "diabético" || setDiabetico(res);
          res.descricao !== "normal" || setDextroNormal(res);
          (res.descricao !== "faixa ideal" &&
            res.tipo !== "circ. abdominal masc") ||
            setFaixaIdealMasc(res);
          (res.descricao !== "risco aumentado" &&
            res.tipo !== "circ. abdominal masc") ||
            setRiscoAumentadoMasc(res);
          (res.descricao !== "risco muito aumentado" &&
            res.tipo !== "circ. abdominal masc") ||
            setRiscoMtAumentadoMasc(res);
          (res.descricao !== "faixa ideal" &&
            res.tipo !== "circ. abdominal fem") ||
            setFaixaIdealFem(res);
          (res.descricao !== "risco aumentado" &&
            res.tipo !== "circ. abdominal fem") ||
            setRiscoAumentadoFem(res);
          (res.descricao !== "risco muito aumentado" &&
            res.tipo !== "circ. abdominal fem") ||
            setRiscoMtAumentadoFem(res);
          res.descricao !== "muito abaixo do peso" || setPesoMtAbaixo(res);
          res.descricao !== "abaixo do peso" || setPesoAbaixo(res);
          res.descricao !== "peso normal" || setPesoNormal(res);
          res.descricao !== "acima do peso" || setPesoAcima(res);
          res.descricao !== "obesidade I" || setObeso1(res);
          res.descricao !== "obesidade II" || setObeso2(res);
          res.descricao !== "obesidade III" || setObeso3(res);
          res.descricao !== "faixa ideal" || setFaixaIdealMasc(res);
          res.descricao !== "risco aumentado" || setRiscoAumentadoMasc(res);
          res.descricao !== "risco muito aumentado" || setRiscoMtAumentadoMasc(res);
          res.descricao !== "faixa ideal" || setFaixaIdealFem(res);
          res.descricao !== "risco aumentado" || setRiscoAumentadoFem(res);
          res.descricao !== "risco muito aumentado" || setRiscoMtAumentadoFem(res);

          //Falta os consumos

        })
      )
      .catch((error) => alert(error));
  };

  useEffect(() => {
    getRegras();
  }, []);

  const handleUpdate = (e) => {
    console.log(regras);
    e.preventDefault();
    api
      .patch(`/regras`, { data: regras })
      .then((res) => {
        alert(res.data.success);
        getRegras();
      })
      .catch((err) => alert(err));
  };

  const cancelUpdate = (e) => {
    getRegras();
  };


  return (
    <Container>
      <br />
      <center>
        <h3>Defina os valores para a configuração dos itens seguintes</h3>
      </center>
      <ConfigForm>
        <LabelColumn>
          <center>
            <h3>Pressão</h3>
          </center>
          <ConfigTable>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Pressão mínima</th>
                  <th>Pressão máxima</th>
                </tr>
                <tr>
                  <th>Hipertensão</th>
                  <td>
                    <ConfigInput
                      name={"minimo"}
                      value={hipertensao.minimo}
                      onChange={(e) => setHipertensao((x) => ({ ...x, minimo: e.target.value }))}

                    />
                  </td>
                  <td>
                    <ConfigInput
                      name={"maximo"}
                      value={hipertensao.maximo}
                      onChange={(e) => setHipertensao((x) => ({ ...x, maximo: e.target.value }))}
                    />
                  </td>
                </tr>
              </thead>
            </table>

          </ConfigTable>
          <br />
          <br />
          <center>
            <h3>Peso</h3>
          </center>
          <ConfigTable>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Peso mínimo</th>
                  <th>Peso máximo</th>
                </tr>
                <tr>
                  <th>Muito abaixo do peso</th>
                  <td>
                    <ConfigInput
                      value={pesoMtAbaixo.minimo}
                      onChange={(e) => setPesoMtAbaixo((x) => ({ ...x, minimo: e.target.value }))}

                    />

                  </td>
                  <td>
                    <ConfigInput
                      value={pesoMtAbaixo.maximo}
                      onChange={(e) => setPesoMtAbaixo((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Abaixo do peso</th>
                  <td>
                    <ConfigInput
                      value={pesoAbaixo.minimo}
                      onChange={(e) => setPesoAbaixo((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={pesoAbaixo.maximo}
                      onChange={(e) => setPesoAbaixo((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Peso normal</th>
                  <td>
                    <ConfigInput
                      value={pesoNormal.minimo}
                      onChange={(e) => setPesoNormal((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={pesoNormal.maximo}
                      onChange={(e) => setPesoNormal((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Acima do peso</th>
                  <td>
                    <ConfigInput
                      value={pesoAcima.minimo}
                      onChange={(e) => setPesoAcima((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={pesoAcima.maximo}
                      onChange={(e) => setPesoAcima((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Obesidade I</th>
                  <td>
                    <ConfigInput
                      value={obeso1.minimo}
                      onChange={(e) => setObeso1((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={obeso1.maximo}
                      onChange={(e) => setObeso1((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Obesidade II</th>
                  <td>
                    <ConfigInput
                      value={obeso2.minimo}
                      onChange={(e) => setObeso2((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={obeso2.maximo}
                      onChange={(e) => setObeso2((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Obesidade III</th>
                  <td>
                    <ConfigInput
                      value={obeso3.minimo}
                      onChange={(e) => setObeso3((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={obeso3.maximo} l
                      onChange={(e) => setObeso3((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
              </thead>
            </table>

          </ConfigTable>
          <br />
          <br />
          <center>
            <h3>Diabetes</h3>
          </center>
          <ConfigTable>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Dextro mínimo</th>
                  <th>Dextro máximo</th>
                </tr>
                <tr>
                  <th>Normal</th>
                  <td>
                    <ConfigInput
                      value={dextroNormal.minimo}
                      onChange={(e) => setDextroNormal((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={dextroNormal.maximo}
                      onChange={(e) => setDextroNormal((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Diabético</th>
                  <td>
                    <ConfigInput
                      value={diabetico.minimo}
                      onChange={(e) => setDiabetico((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={diabetico.maximo}
                      onChange={(e) => setDiabetico((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
              </thead>
            </table>

          </ConfigTable>
          <br />
          <br />
          <center>
            <h3>Circunferência Abdominal</h3>
          </center>
          <ConfigTable>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Circ. mínima</th>
                  <th>Circ. máxima</th>
                </tr>
                <tr>
                  <th>Faixa ideal (masculino)</th>
                  <td>
                    <ConfigInput
                      value={faixaIdealMasc.minimo}
                      onChange={(e) => setFaixaIdealMasc((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={faixaIdealMasc.maximo}
                      onChange={(e) => setFaixaIdealMasc((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Risco aumentado (masculino)</th>
                  <td>
                    <ConfigInput
                      value={riscoAumentadoMasc.minimo}
                      onChange={(e) => setRiscoAumentadoMasc((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={riscoAumentadoMasc.maximo}
                      onChange={(e) => setRiscoAumentadoMasc((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Risco muito aumentado (masculino)</th>
                  <td>
                    <ConfigInput
                      value={riscoMtAumentadoMasc.minimo}
                      onChange={(e) => setRiscoMtAumentadoMasc((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={riscoMtAumentadoMasc.maximo}
                      onChange={(e) => setRiscoMtAumentadoMasc((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Faixa ideal (feminino)</th>
                  <td>
                    <ConfigInput
                      value={faixaIdealFem.minimo}
                      onChange={(e) => setFaixaIdealFem((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={faixaIdealFem.maximo}
                      onChange={(e) => setFaixaIdealFem((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Risco aumentado (feminino)</th>
                  <td>
                    <ConfigInput
                      value={riscoAumentadoFem.minimo}
                      onChange={(e) => setRiscoAumentadoFem((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={riscoAumentadoFem.maximo}
                      onChange={(e) => setRiscoAumentadoFem((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
                <tr>
                  <th>Risco muito aumentado (feminino)</th>
                  <td>
                    <ConfigInput
                      value={riscoMtAumentadoFem.minimo}
                      onChange={(e) => setRiscoMtAumentadoFem((x) => ({ ...x, minimo: e.target.value }))} />
                  </td>
                  <td>
                    <ConfigInput
                      value={riscoMtAumentadoFem.maximo}
                      onChange={(e) => setRiscoMtAumentadoFem((x) => ({ ...x, maximo: e.target.value }))} />
                  </td>
                </tr>
              </thead>
            </table>

          </ConfigTable>

          <br />
          <br />
          <center>
            <h3>Defina a quantidade IDEAL para cada alimento</h3>
          </center>
          <br />
          <br />


          <ConfigTable>



            <CheckBoxContainer>

              <label>Consumo de Carne</label>
              <CheckBoxes>
                <CheckBoxLabel>
                  <label >Nunca/raramente </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_carne.includes("Nunca/raramente")}
                    name="consumo_de_carne"
                    onChange={(e) => setConsumo_de_carne((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_carne.includes("1 vez por dia")}
                    name="consumo_de_carne"
                    onChange={(e) => setConsumo_de_carne((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_carne.includes("2 vez por dia")}
                    name="consumo_de_carne"
                    onChange={(e) => setConsumo_de_carne((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_carne.includes("3 vezes ou mais por dia")}
                    name="consumo_de_carne"
                    onChange={(e) => setConsumo_de_carne((x) => !x.includes("3 vezes ou mais por dia") ?  [...x, "3 vezes ou mais por dia"] : x.filter((a) => a !== "3 vezes ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de leite</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_leite.includes("Nunca/raramente")}
                    name="consumo_de_leite"
                    onChange={(e) => setConsumo_de_leite((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_leite.includes("1 vez por dia")}
                    name="consumo_de_leite"
                    onChange={(e) => setConsumo_de_leite((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_leite.includes("2 vezes por dia")}
                    name="consumo_de_leite"
                    onChange={(e) => setConsumo_de_leite((x) => !x.includes("2 vezes por dia") ?  [...x, "2 vezes por dia"] : x.filter((a) => a !== "2 vezes por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_leite.includes("3 vez ou mais por dia")}
                    name="consumo_de_leite"
                    onChange={(e) => setConsumo_de_leite((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de Óleo</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_oleo.includes("Nunca/raramente")}
                    name="consumo_de_oleo"
                    onChange={(e) => setConsumo_de_oleo((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_oleo.includes("1 vez por dia")}
                    name="consumo_de_oleo"
                    onChange={(e) => setConsumo_de_oleo((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_oleo.includes("2 vez por dia")}
                    name="consumo_de_oleo"
                    onChange={(e) => setConsumo_de_oleo((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_oleo.includes("3 vez ou mais por dia")}
                    name="consumo_de_oleo"
                    onChange={(e) => setConsumo_de_oleo((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de petiscos</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_petiscos.includes("Nunca/raramente")}
                    name="consumo_de_petiscos"
                    onChange={(e) => setConsumo_de_petiscos((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_petiscos.includes("1 vez por dia")}
                    name="consumo_de_petiscos"
                    onChange={(e) => setConsumo_de_petiscos((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_petiscos.includes("2 vez por dia")}
                    name="consumo_de_petiscos"
                    onChange={(e) => setConsumo_de_petiscos((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_petiscos.includes("3 vez ou mais por dia")}
                    name="consumo_de_petiscos"
                    onChange={(e) => setConsumo_de_petiscos((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de cereais</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_cereais.includes("Nunca/raramente")}
                    name="consumo_de_cereais"
                    onChange={(e) => setConsumo_de_cereais((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_cereais.includes("1 vez por dia")}
                    name="consumo_de_cereais"
                    onChange={(e) => setConsumo_de_cereais((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_cereais.includes("2 vez por dia")}
                    name="consumo_de_cereais"
                    onChange={(e) => setConsumo_de_cereais((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_cereais.includes("3 vez ou mais por dia")}
                    name="consumo_de_cereais"
                    onChange={(e) => setConsumo_de_cereais((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de legumes</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_legumes.includes("Nunca/raramente")}
                    name="consumo_de_legumes"
                    onChange={(e) => setConsumo_de_legumes((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_legumes}
                    name="consumo_de_legumes"
                    onChange={(e) => setConsumo_de_legumes((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_legumes.includes("2 vez por dia")}
                    name="consumo_de_legumes"
                    onChange={(e) => setConsumo_de_legumes((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_legumes.includes("3 vez ou mais por dia")}
                    name="consumo_de_legumes"
                    onChange={(e) => setConsumo_de_legumes((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de hortaliças</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_hortalicas.includes("Nunca/raramente")}
                    name="consumo_de_hortalicas"
                    onChange={(e) => setConsumo_de_hortalicas((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_hortalicas.includes("1 vez por dia")}
                    name="consumo_de_hortalicas"
                    onChange={(e) => setConsumo_de_hortalicas((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_hortalicas.includes("2 vez por dia")}
                    name="consumo_de_hortalicas"
                    onChange={(e) => setConsumo_de_hortalicas((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia </label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_hortalicas.includes("3 vez ou mais por dia")}
                    name="consumo_de_hortalicas"
                    onChange={(e) => setConsumo_de_hortalicas((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de sobremesas</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_sobremesas.includes("Nunca/raramente")}
                    name="consumo_de_sobremesas"
                    onChange={(e) => setConsumo_de_sobremesas((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_sobremesas.includes("1 vez por dia")}
                    name="consumo_de_sobremesas"
                    onChange={(e) => setConsumo_de_sobremesas((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_sobremesas.includes("2 vez por dia")}
                    name="consumo_de_sobremesas"
                    onChange={(e) => setConsumo_de_sobremesas((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_sobremesas.includes("3 vez ou mais por dia")}
                    name="consumo_de_sobremesas"
                    onChange={(e) => setConsumo_de_sobremesas((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de bebidas</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_bebidas.includes("Nunca/raramente")}
                    name="consumo_de_bebidas"
                    onChange={(e) => setConsumo_de_bebidas((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_bebidas.includes("1 vez por dia")}
                    name="consumo_de_bebidas"
                    onChange={(e) => setConsumo_de_bebidas((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_bebidas.includes("2 vez por dia")}
                    name="consumo_de_bebidas"
                    onChange={(e) => setConsumo_de_bebidas((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_bebidas.includes("3 vez ou mais por dia")}
                    name="consumo_de_bebidas"
                    onChange={(e) => setConsumo_de_bebidas((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>
            <CheckBoxContainer>

              <label>Consumo de água</label>
              <CheckBoxes>


                <CheckBoxLabel>
                  <label >Nunca/raramente</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_agua.includes("Nunca/raramente")}
                    name="consumo_de_agua"
                    onChange={(e) => setConsumo_de_agua((x) => !x.includes("Nunca/raramente") ?  [...x, "Nunca/raramente"] : x.filter((a) => a !== "Nunca/raramente"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >1 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_agua.includes("1 vez por dia")}
                    name="consumo_de_agua"
                    onChange={(e) => setConsumo_de_agua((x) => !x.includes("1 vez por dia") ?  [...x, "1 vez por dia"] : x.filter((a) => a !== "1 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >2 vez por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_agua.includes("2 vez por dia")}
                    name="consumo_de_agua"
                    onChange={(e) => setConsumo_de_agua((x) => !x.includes("2 vez por dia") ?  [...x, "2 vez por dia"] : x.filter((a) => a !== "2 vez por dia"))}
                  />

                </CheckBoxLabel>
                <CheckBoxLabel>
                  <label >3 vez ou mais por dia</label>
                  <CheckBox
                    type={"checkbox"}
                    value={consumo_de_agua.includes("3 vez ou mais por dia")}
                    name="consumo_de_agua"
                    onChange={(e) => setConsumo_de_agua((x) => !x.includes("3 vez ou mais por dia") ?  [...x, "3 vez ou mais por dia"] : x.filter((a) => a !== "3 vez ou mais por dia"))}
                  />

                </CheckBoxLabel>
              </CheckBoxes>
            </CheckBoxContainer>


          </ConfigTable>
          {/* value={formState.pressao} value={formState.peso} value={formState.visual}
        value={formState.diabetes} value={formState.alergia}  value={formState.colesterol} */}
        </LabelColumn>
      </ConfigForm>
      <Button>
        <button onClick={handleUpdate} type="button">
          Salvar
        </button>
        <button onClick={cancelUpdate} type="button">
          Cancelar
        </button>
      </Button>
    </Container>
  );
}
