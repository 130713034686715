import styled from "styled-components";

export const SwitchDiv = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 420px;
  height: 60px;
  background: var(--table-color);
  position: relative;
  display: flex;
  column-gap: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  &:after {
    position: absolute;
    z-index: 1;
    content: "";
    top: 56%;
    right: -45px;
    width: 45px;
    height: 26px;
    background: transparent;
    border-bottom-left-radius: 20px;
    box-shadow: -34px 5px 0px 5px var(--table-color);
  }

  textarea:focus,
  input:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }
  input {
    width: 200px;
  }
  [type="search"] {
    border-radius: 35px;
    padding: 2px 10px;
    border: none;
  }
`;

export const SwitchButton = styled.button`
    position: relative;
    border: 0;
    width: 50px;
    height: 70px;
    z-index: 1;
    width: 100;
    color: var(--black-color);
    text-align: center
    font-weight: 400;
    letter-spacing: 0.05em;
    transition: 0.3s;
    background: transparent;
    
    &#${({ selected }) => (!selected ? "nome-switch {" : "rm-switch {")};
    transform: translateY(-30px);
    color: var(--white-color);
    }
`;

export const SwitchIndicator = styled.div`
  position: absolute;
  border-radius: 50%;
  border: 4px solid var(--white-color);
  top: -30px;
  left: 36px;
  display: flex;
  background-color: var(--background-color);
  transition: 0.3s;
  transform: ${({ selected }) => !selected || "translateX(68px)"};
  height: 60px;
  width: 60px;

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    right: 51px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-right-radius: 20px;
    box-shadow: 0px -10px 0 0 var(--white-color);
  }

  &:after {
    position: absolute;
    content: "";
    top: 50%;
    left: 51px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-top-left-radius: 20px;
    box-shadow: 0px -10px 0 0 var(--white-color);
  }
`;
