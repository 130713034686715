import React from "react";
import { SwitchDiv, SwitchButton, SwitchIndicator } from "./style";

const Switch = (props) => {
  const {
    selected,
    setSelected,
    placeholders,
    searchFunction,
    setRm,
    setName,
    setPage,
  } = props;
  return (
    <SwitchDiv>
      <SwitchIndicator selected={selected} />
      <SwitchButton
        id={"nome-switch"}
        selected={selected}
        onClick={() => {
          setSelected(0);
          setRm("");
          setPage(0);
          document.getElementById("search_filter").value = "";
        }}
      >
        Nome
      </SwitchButton>
      <SwitchButton
        id={"rm-switch"}
        selected={selected}
        onClick={() => {
          setSelected(1);
          setName("");
          setPage(0);
          document.getElementById("search_filter").value = "";
        }}
      >
        RM
      </SwitchButton>
      <input
        id="search_filter"
        type="search"
        placeholder={!selected ? placeholders[0] : placeholders[1]}
        onChange={(e) => searchFunction(e.target.value)}
      />
    </SwitchDiv>
  );
};

export default Switch;
