import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 80px 0;
  flex-direction: column;
  position: relative;
`;

export const Container = styled.div`
  border-radius: 2rem;
  box-shadow: 0px 40px 43px 30px rgba(0, 0, 0, 0.1);
  width: 80%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  padding: 20px;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  padding: 20px 0;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  /* min-width: 100px;
  max-width: 500px; */
`;

export const Label = styled.div`
  color: var(--primary-color);
  text-transform: uppercase;
`;

export const Value = styled.div`
  padding: 0.5rem;
  background: none;
  color: var(--font-color);
  justify-content: flex-start;
  border-radius: 0.3rem;
  border-bottom: 2px solid #ccc;
`;
