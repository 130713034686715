import {
  Form,
  Background,
  Title,
  InputLabel,
  Select,
  Button,
  Input,
  Label,
  CloseButton,
  RadioContainer,
} from "./style";
import React, { useState, useRef, useEffect } from "react";
import cursos from "../../../assets/json/cursos.json";
import api from "../../../services/api";
import Swal from "sweetalert2";

export default function EditarAluno({ showModal, setShowModal, dados }) {
  const modalRef = useRef();
  const [Nome, setNome] = useState("");
  const [Rm, setRm] = useState(0);
  const [Genero, setGenero] = useState("");
  const [DataNascimento, setDataNascimento] = useState("");
  const [Curso, setCurso] = useState("");
  const [Periodo, setPeriodo] = useState("");

  useEffect(() => {
    if (dados) {
      setNome(dados.nome);
      setRm(dados.rm);
      setGenero(dados.genero);
      setDataNascimento(dados.data_nascimento);
      setCurso(dados.curso);
      setPeriodo(dados.periodo);
    }
  }, [dados]);

  async function handleSubmit(e) {
    e.preventDefault();

    const data = {
      nome: Nome,
      genero: Genero,
      data_nascimento: DataNascimento,
      curso: Curso,
      periodo: Periodo,
    };

    api
      .patch(`/alunos/update/${Rm}`, { data: data })
      .then((res) => {
        if (res.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: res.error,
            icon: "warning",
            confirmButtonColor: "var(--background-color)",
          });
        Swal.fire({
          title: "Sucesso!",
          text: res.data.success,
          icon: "success",
          confirmButtonColor: "var(--background-color)",
        });
        setShowModal(false);
      })
      .catch((err) => alert(err));
  }

  return (
    <Background
      show={showModal}
      ref={modalRef}
      onClick={(e) => {
        if (modalRef.current === e.target) {
          setShowModal(!showModal);
        }
      }}
    >
      <Form onSubmit={handleSubmit}>
        <CloseButton onClick={() => setShowModal(false)} />
        <Title>Editar Aluno</Title>
        <InputLabel>
          <Label htmlFor="rm">RM</Label>
          <Input
            id="rm"
            value={Rm}
            type="number"
            onChange={(e) => setRm(e.target.value)}
            placeholder="RH"
            disabled
          />
        </InputLabel>
        <InputLabel>
          <Label htmlFor="nome">Nome</Label>
          <Input
            id="nome"
            type="name"
            value={Nome}
            onChange={(e) => setNome(e.target.value)}
            placeholder="Nome"
          />
        </InputLabel>
        <InputLabel>
          <Label>Gênero</Label>
          <RadioContainer>
            <Input
              name="genero"
              id="femenino"
              type="radio"
              value="Feminino"
              checked={Genero === "Feminino"}
              onChange={(e) => setGenero(e.target.value)}
            />
            <label htmlFor="femenino">Feminino</label>
            <Input
              name="genero"
              id="masculino"
              type="radio"
              value="Masculino"
              checked={Genero === "Masculino"}
              onChange={(e) => setGenero(e.target.value)}
            />
            <label htmlFor="masculino">Masculino</label>
          </RadioContainer>
        </InputLabel>
        <InputLabel>
          <Label htmlFor="data-nascimento">Data de Nascimento</Label>
          <Input
            type="date"
            value={DataNascimento}
            onChange={(e) => setDataNascimento(e.target.value)}
          />
        </InputLabel>
        <InputLabel>
          <Label htmlFor="curso">Curso</Label>
          <Select
            id="curso"
            value={Curso}
            onChange={(e) => setCurso(e.target.value)}
          >
            {cursos.map((c, i) => (
              <option key={i}>{c}</option>
            ))}
          </Select>
        </InputLabel>
        <InputLabel>
          <Label>Período</Label>
          <RadioContainer>
            <Input
              type="radio"
              name="periodo"
              id="manha"
              value="Manhã"
              checked={Periodo === "manhã"}
              onChange={(e) => setPeriodo(e.target.value)}
            />
            <label htmlFor="manha">Manhã</label>
            <Input
              type="radio"
              name="periodo"
              id="tarde"
              value="Tarde"
              checked={Periodo === "Tarde"}
              onChange={(e) => setPeriodo(e.target.value)}
            />
            <label htmlFor="tarde">Tarde</label>
            <Input
              type="radio"
              name="periodo"
              id="noite"
              value="Noite"
              checked={Periodo === "Noite"}
              onChange={(e) => setPeriodo(e.target.value)}
            />
            <label htmlFor="noite">Noite</label>
          </RadioContainer>
        </InputLabel>
        <Button>Salvar</Button>
      </Form>
    </Background>
  );
}
