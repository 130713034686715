import styled from "styled-components";
import { MdClose } from "react-icons/md";

export const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 1000;
  display: ${(props) => (props.show ? "flex" : "none")};
`;

export const Form = styled.form`
  width: fit-content;
  height: fit-content;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 50px;
  padding-top: 50px;
  padding-bottom: 20px;
  border-radius: 10px;
  gap: 20px;
`;

export const Title = styled.h1``;

export const InputLabel = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  flex-direction: column;
`;

export const Input = styled.input`
  padding: 0.5rem;
  background: none;
  color: var(--font-color);
  border: 2px solid var(--font-color);
  border-radius: 0.3rem;
  border-bottom: 2px solid #ccc;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: var(--font-color);
  }

  input[type="radio"] {
    border: 1px solid red;
  }
`;

export const Label = styled.label`
  width: 100%;
`;

export const Select = styled.select`
  padding: 0.5rem;
  background: none;
  color: var(--font-color);
  border: 2px solid var(--font-color);
  border-radius: 0.3rem;
  border-bottom: 2px solid #ccc;
`;

export const Button = styled.button`
  border-radius: 0.3rem;
  text-transform: uppercase;
  border: none;
  color: var(--white-color);
  background: var(--background-color);
  padding: 10px 20px;
  transition: transform 0.2s;
  margin-top: 20px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const RadioContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 30px 1fr;
  grid-template-areas:
    ". ."
    ". .";
`;

export const CloseButton = styled(MdClose)`
  position: absolute;
  color: var(--background-color);
  top: 0;
  right: 0;
  width: 56px;
  height: 56px;
  cursor: pointer;
`;
