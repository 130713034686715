import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Background,
  Page,
  Subpage,
  Images,
  Content,
  Title,
  Table,
  Breaker,
  Assinaturas
} from "./style";
import alunoSaudavel from "../../assets/Images/aluno_saudavel.jpg";
import fiec from "../../assets/Images/fiec.png";
import prefeitura from "../../assets/Images/prefeitura.jpg";
import PdfButtons from "../../components/PdfButtons";
import api from "../../services/api";
import Swal from "sweetalert2";
import Auth from "../../auth";

export default function Pdfalunos() {
  const [Dados, setDados] = useState([]);
  const [alunos, setalunos] = useState([]);
  const [coletas, setColetas] = useState([]);
  const [data_emissao, setData_emissao] = useState("");
  const [rm, setRm] = useState("");
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    
    if (rm) {
    api
      .get(
        `/generatepdf/${rm}`
    )
    
      .then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        setDados(response.data.response);
        setalunos(response.data.alunos);
        setColetas(response.data.coletas);
        setData_emissao(response.data.dataPDF);
      })
      .catch((error) => {
      console.error(error);
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        })
  });
  }
  }, [location.state, rm]);

  useEffect(() => {
    setRm(location?.state?.rm)
  }, [location?.state?.rm]);



  return (
    <Background>
      <PdfButtons />
      <Page>
        <Subpage>
          <Breaker ref={ref}>
            <Images>
              <img src={fiec} alt="erro" />
              <img src={alunoSaudavel} alt="erro" />
              <img src={prefeitura} alt="erro" />
            </Images>
            <br />
            <Content>
              <Table>
            <tbody>
            <tr>
                <td><b>RM:</b>  {alunos?.rm} </td>
                <td className="td-centro" ><b>Nome:</b>  {alunos?.nome} </td>
                <td className="td-direita"><b>Gênero:</b>  {alunos?.genero} </td>
            </tr>
            <tr>
                <td><b>Nascimento:</b>  {alunos?.data_nascimento} </td>
                <td className="td-centro"><b>Curso:</b>  {alunos?.curso} </td>
                <td className="td-direita"><b>Periodo:</b>  {alunos?.periodo} </td>
            </tr>
            <tr>
                <td><b>PA Min:</b>  {coletas?.pressao_minima} </td>
                <td className="td-centro"><b>PA Max:</b>  {coletas?.pressao_maxima} </td>
                <td className="td-direita"><b>Hipertensão arterial:</b>  {coletas?.pressao} </td>
            </tr>
            <tr>
                <td><b>Dextro:</b>  {coletas?.dextro} </td>
                <td className="td-centro"><b>Diabetes:</b>  {coletas?.diabetes} </td>
                <td className="td-direita"><b>Tipo sanguineo:</b>  {coletas?.tipo_sanguineo} </td>
            </tr>
            <tr>
                <td><b>Peso (kg):</b>  {coletas?.peso_kg} </td>
                <td className="td-centro"><b>Estatura (m):</b>  {coletas?.estatura_metros} </td>
                <td className="td-direita"><b>IMC:</b>  {coletas?.imc} </td>
            </tr>
            <tr>
                <td><b>Circ. Abdominal (cm):</b>  {coletas?.circ_abdominal_cm} </td>
                <td className="td-centro"><b>Risco Cardíaco Segundo Circ. Abdominal:</b>  {coletas?.res_circ_abdominal} </td>
                <td></td>
            </tr>
            <tr>
                <td><b>Utiliza Óculos:</b>  {coletas?.oculos} </td>
                <td className="td-centro"><b>Visual (Direita):</b>  {coletas?.acuidade_visual_direita} </td>
                <td className="td-direita"><b>Visual (Esquerda):</b>  {coletas?.acuidade_visual_esquerda} </td>
            </tr>
            <tr>
                <td><b>Convulsões:</b>  {coletas?.convulsoes} </td>
                <td className="td-centro"><b>Alergias:</b>  {coletas?.alergias} </td>
                <td className="td-direita"><b>Doenças Crônicas:</b>  {coletas?.doenca_cronica_tratamento} </td>
            </tr>
            </tbody>
            </Table>
       
            <Title>Avaliação Nutricional</Title>
        
        <p style={{ fontSize: "12px"}}> Considerando as recomendações da OMS (Organização Mundial de Saúde) para a promoção da saúde e prevenção de
            doenças.</p>

        <Table>
        <tbody>
            <tr>
                <td><b>Praticar de atividade física:</b>  {coletas?.atividade_fisica} </td>
            </tr>
            <tr>
                <td><b>Ingerir 8 ou mais copos de água por dia:</b>  {coletas?.consumo_agua} </td>
                <td><b>Quantidade:</b>  {coletas?.qtos_copos_agua} </td>
            </tr>
            <tr>
                <td><b>Fazer dieta ou suplementação COM conhecimento/orientação nutricional:</b>  {coletas?.suplementacao_alimentar_uili} </td>
                <td></td>
            </tr>
            <tr>
                <td><b>Fazer 4 ou mais refeições ao dia:</b>  {coletas?.qtas_refeicoes_dia} </td>
            </tr>
            <tr>
                <td><b>Quantas refeições em casa:</b>  {coletas?.qtas_refeicoes_em_casa} </td>
                <td><b>Refeições realizadas fora de casa:</b>  {coletas?.qtas_refeicoes_fora} </td>
            </tr>
            <tr>
                <td><b>Adicionar sal às refeições já prontas:</b>  {coletas?.adiciona_sal_dps_pronto} </td>
                <td><b>Adicionar açúcar aos líquidos ingeridos:</b>  {coletas?.adiciona_acucar_liquidos} </td>
            </tr>
          </tbody>
        </Table>
        <Title>Bioimpedância</Title>
        <Table>
          <tbody>
            <tr>
                <td><b>% Gordura:</b>  {coletas?.bioimpedancia_de_gordura} </td>
                <td className="td-centro"><b>Massa Magra (kg):</b>  {coletas?.bioimpedancia_massa_magra_kg} </td>
                <td className="td-direita"><b>Massa Gorda (kg):</b>  {coletas?.bioimpedancia_massa_gorda_kg} </td>
            </tr>
          </tbody>
        </Table>
        <br />
        <div id="bio">
            <Title>Avaliação Nutricional</Title>
        </div>
        <p style={{ fontSize: "12px"}}>Considerando as recomendações da OMS (Organização Mundial de Saúde) para a promoção da saúde e prevenção de
            doenças.</p>

        <p style={{ fontSize: "14px"}}><b><u>Seus hábitos em relação aos itens abaixo estão:</u></b></p>

         <Table>
            <tr>
                <span style={{color: "rgba(0, 0, 255, 0.63)"}}> A – Adequado </span>/
                <span style={{color: "#ff9100"}}> PA – Parcialmente Adequado </span>/
                <span style={{color: "rgb(255, 0, 0)"}}> I – Inadequado </span>
            </tr>
        </Table>

        <b>Frequencia de Consumo:</b>
        <Table>
            <thead>
              <th>Grupo</th>
              <th style={{ textAlign: "center", width: "1%" }}>Class.</th>
            </thead>
            <tbody className="tbody-ultimo-tabela">
            <tr>
                <td>Leite e derivados- ex: leite, iogurte, queijo, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.leite_resultado} </td>
            </tr>
            <tr>
                <td>Carne e ovos- ex: carne de boi, ovo, peixe, linguiça, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.carne_resultado} </td>
            </tr>
            <tr>
                <td>Óleos- ex: azeite, óleo, margarina, maionese</td>
                <td style={{ textAlign: "center"}}> {coletas?.oleo_resultado} </td>
            </tr>
            <tr>
                <td>Petiscos e enlatados- ex: milho, salgadinhos, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.petisco_resultado} </td>
            </tr>
            <tr>
                <td>Cereais, massas e tubérculos- Ex: arroz, macarrão, batata, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.cereal_resultado} </td>
            </tr>
            <tr>
                <td>Leguminosas- ex: feijão, vagem, ervilha, soja, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.legume_resultado} </td>
            </tr>
            <tr>
                <td>Hortaliças e frutas- ex: alface, tomate, banana, maçã, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.hortalicas_resultado} </td>
            </tr>
            <tr>
                <td>Sobremesas e doces- ex: sorvete, chocolate, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.sobremesa_resultado} </td>
            </tr>
            <tr>
                <td>Bebidas- ex: café, suco, refrigerante, etc.</td>
                <td style={{ textAlign: "center"}}> {coletas?.bebida_resultado} </td>
            </tr>
            <tr>
                <td>Produtos diet e light- ex: adoçante, margarina, refrigerante, etc.</td>
                <td style={{ textAlign: "center"}}>{coletas?.diet_resultado} </td>
            </tr>
            </tbody>
          </Table>
            </Content>
          </Breaker>
        </Subpage>
      </Page>
    </Background>
  );
}
