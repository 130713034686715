import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0;
`;

export const Button = styled.button`
  border-radius: 0.3rem;
  text-transform: uppercase;
  border: none;
  color: var(--white-color);
  background: var(--background-color);
  padding: 10px 20px;
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.5;
  }

  &:hover:enabled {
    transform: scale(1.1);
  }
`;

export const Counter = styled.div``;
