import home from "../../assets/Images/home.jpg";
import { Background, Card, Img, P } from "./style";

export default function Index() {
  return (
    <Background>
      <Card>
        <Img src={home} alt="home" />
        <P>
          O programa é prestado a todos os alunos ingressantes nos cursos
          técnicos da Fiec, que passam por avaliação da pressão arterial,
          glicemia, dados antropométricos, cálculo do IMC (Índice de Massa
          Corpórea), teste de bioimpedância, avaliação da acuidade visual,
          tipagem sanguínea, além de receberem orientações sobre hábitos de vida
          saudável com nutrição adequada e prática de esportes.
        </P>
      </Card>
    </Background>
  );
}
