import styled from "styled-components";
import { FiArrowLeft } from "react-icons/fi";

export const Container = styled.div`
  cursor: pointer;
  width: 65px;
  height: 65px;
  position: absolute;
  top: 50px;
  left: 50px;
`;

export const Icon = styled(FiArrowLeft)`
  font-size: 50px;
  color: var(--background-color);
  transition: 0.25s;

  &:hover {
    color: var(--primary-color);
    transform: translateX(-5px);
  }
`;
