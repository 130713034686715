import React, { useReducer, useEffect, useState } from "react";
import api from "../../services/api";
import { useParams, useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import cursos from "../../assets/json/cursos.json";
import { FormRegistrar, DivForm, Button } from "./style";
import ReturnArrow from "../../components/ReturnArrow";

const fnFormReducer = (state, action) => {
  switch (action.type) {
    case "ATUALIZA":
      return { ...state, [action.name]: action.value };

    case "CARREGA":
      return action.state;

    default:
      return state;
  }
};
const initialState = {};

export default function Registrar() {
  const [formState, dispatch] = useReducer(fnFormReducer, initialState);
  const { id } = useParams();
  const navigate = useNavigate();
  const updateForm = (e) => {
    dispatch({
      type: "ATUALIZA",
      name: e.target.name,
      value: e.target.value,
    });
  };

  useEffect(() => {
    if (id) {
      api
        .get(`/alunos/${id}`)
        .then((response) =>
          dispatch({
            type: "CARREGA",
            state: response.data.aluno,
          })
        )
        .catch((error) => alert(error));
    }
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    if (id) {
      console.log(formState);
      // UPDATE
      api
        .patch(`/alunos/update/${id}`, { data: formState })
        .then((res) => {
          alert(res.data.success);
          navigate(`/alunos`);
        })
        .catch((err) => alert(err));
    } else {
      // RECORD
      api.post(`/alunos/record`, { data: formState }).then((res) => {
        alert(res.data.success);
        navigate(`/alunos`);
      });
    }
  };

  return (
    <>
      <ReturnArrow redirect={`/alunos`} />
      <center>
        <DivForm>
          <FormRegistrar>
            <div>
              <label for="nome">Nome</label>
              <input
                type="text"
                name="nome"
                id="nome"
                placeholder=""
                aria-describedby="helpId"
                value={formState.nome}
                onChange={updateForm}
                required
              />
            </div>
            <div>
              <label for="rm">RM</label>
              <input
                type="number"
                name="rm"
                id="rm"
                placeholder=""
                aria-describedby="helpId"
                value={formState.rm}
                onChange={updateForm}
                required
              />
            </div>
            <div>Gênero</div>
            <div>
              <label>
                <input
                  type="radio"
                  name="genero"
                  id="genero_masculino"
                  value="Masculino"
                  checked={formState.genero === "Masculino"}
                  onChange={updateForm}
                />{" "}
                Masculino
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="genero"
                  id="genero_feminino"
                  value="Feminino"
                  checked={formState.genero === "Feminino"}
                  onChange={updateForm}
                />{" "}
                Feminino
              </label>
            </div>
            <div>
              <label for="data_nascimento">Data de Nascimento</label>
              <input
                type="date"
                name="data_nascimento"
                id="data_nascimento"
                aria-describedby="helpId"
                placeholder=""
                value={formState.data_nascimento}
                onChange={updateForm}
              />
            </div>
            <div>
              <label for="curso">Curso</label>
              <select
                name="curso"
                id="curso"
                selected={formState.curso}
                value={formState.curso}
                onChange={updateForm}
              >
                <option selected disabled hidden>
                  Selecione o curso
                </option>
                {cursos.map((curso) => (
                  <option value={curso}>{curso}</option>
                ))}
              </select>
            </div>
            <div>Período</div>
            <div>
              <label>
                <input
                  type="radio"
                  name="periodo"
                  id="manha"
                  value="Manhã"
                  checked={formState.periodo === "Manhã"}
                  onChange={updateForm}
                  required
                />
                Manhã
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="periodo"
                  id="tarde"
                  value="Tarde"
                  checked={formState.periodo === "Tarde"}
                  onChange={updateForm}
                />
                Tarde
              </label>
            </div>
            <div>
              <label>
                <input
                  type="radio"
                  name="periodo"
                  id="noite"
                  value="Noite"
                  checked={formState.periodo === "Noite"}
                  onChange={updateForm}
                />
                Noite
              </label>
            </div>
            <center>
              <Button onClick={submitForm} type="submit">
                Registrar
              </Button>
            </center>
          </FormRegistrar>
        </DivForm>
      </center>
    </>
  );
}
