import React, { useState, useEffect } from "react";
import { FiEdit, FiTrash2, FiFile, FiEye } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../services/api";
import { DivButton, Pesquisa, TabelaAll, Container } from "./style";
import formatDateString from "../../services/formatDate";
import formatDateTimeString from "../../services/formatDateTimeString";
import Switch from "../../components/Switch";
import Pagination from "../../components/Pagination";
import ModalEdit from "../../components/Modal/EditarAluno";
import Texts from "../../assets/json/texts.json";

export default function Alunos() {
  const navigate = useNavigate();
  const [auth] = useState(localStorage.getItem("token"));
  const [alunos, setAlunos] = useState([]);
  const [name, setName] = useState("");
  const [rm, setRm] = useState("");
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState(0);
  const [count, setCount] = useState(0);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [Dados, setDados] = useState("");
  const limit = 15;

  const searchQuery = !selected ? `nome=${name}` : `rm=${rm}`;

  const searchPlaceholders = [
    "Procure alunos pelo nome",
    "Procure alunos pelo RM",
  ];

  const getStudents = async () => {
    await api
      .get(`/alunos/?limit=${limit}&offset=${page * limit}&${searchQuery}`)
      .then((response) => {
        setAlunos(response.data.alunos);
        setCount(response.data.count);
      })
      .catch((error) => error !== "TypeError" || alert(error));
  };

  useEffect(() => {
    getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, name, rm]);

  const search = (query) => {
    setPage(0);
    !selected ? setName(query) : setRm(query);
    getStudents();
  };

  const deleteAluno = (rm, student) => {
    Swal.fire({
      title: `Você tem certeza que quer deletar ${student}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      cancelButtonColor: "var(--secundary-color)",
      confirmButtonText: "Deletar",
    }).then((result) => {
      if (result.isConfirmed) {
        api.delete(`/alunos/delete/${rm}`).then((response) => {
          Swal.fire(`Aluno deletado.`, "", "success");
          getStudents();
        });
      }
    });
  };

  return (
    <Container>
      <TabelaAll>
        <Pesquisa>
          <Switch
            selected={selected}
            setSelected={setSelected}
            placeholders={searchPlaceholders}
            searchFunction={search}
            setRm={setRm}
            setName={setName}
            setPage={setPage}
          />
        </Pesquisa>
        <thead>
          <tr>
            <th>RM</th>
            <th>Nome</th>
            <th>Idade</th>
            <th>Gênero</th>
            <th>Nascimento</th>
            <th>Curso</th>
            <th>Período</th>
            <th>Cadastro</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {alunos !== null ? (
            alunos.map((aluno) => (
              <tr key={aluno.rm}>
                <td>{aluno.rm}</td>
                <td>{aluno.nome.toUpperCase()}</td>
                <td>{aluno.idade}</td>
                <td>{aluno.genero.toUpperCase()}</td>
                <td>{formatDateString(aluno.data_nascimento)}</td>
                <td>
                  {aluno.curso.length > 50
                    ? aluno.curso.substring(0, 50).toUpperCase()
                    : aluno.curso.toUpperCase()}
                </td>
                <td>{aluno.periodo.toUpperCase()}</td>
                <td>{formatDateTimeString(aluno.data_cadastro)}</td>
                <td>
                  {/* <Link to={`/registrar/${aluno.rm}`}> */}
                  <FiEdit
                    type="solid"
                    name="pencil"
                    title="Editar Aluno"
                    style={{ textDecoration: "none", cursor: "pointer" }}
                    onClick={() => {
                      setDados(aluno);
                      setShowModalEdit(true);
                    }}
                  ></FiEdit>
                </td>
                <td onClick={() => deleteAluno(aluno.rm, aluno.nome)}>
                  <FiTrash2
                    type="solid"
                    name="trash"
                    title="Deletar Aluno"
                    style={{ cursor: "pointer" }}
                  ></FiTrash2>
                </td>
                <td>
                  <Link to={`/coleta/${aluno.rm}`}>
                    <FiEye
                      name="eye"
                      type="solid"
                      title="Ver coleta"
                      style={{ cursor: "pointer" }}
                    ></FiEye>
                  </Link>
                </td>
                <td>
                  <FiFile
                    name="file-pdf"
                    // type="solid"
                    title="Gerar PDF"
                    type="button"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate("/alunopdf", {
                        state: {
                          rm: aluno?.rm,
                        },
                      });
                    }}
                    // style={{
                    //   gridArea: "p",
                    //   cursor: "pointer",
                    //   opacity: 1,
                    //   pointerEvents: "auto",
                    // }}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">Nenhum aluno encontrado.</td>
            </tr>
          )}
        </tbody>
      </TabelaAll>
      {!alunos || (
        <Pagination
          setPage={setPage}
          page={page}
          itemCount={count}
          itemsPerPage={limit}
        />
      )}
      <ModalEdit
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        dados={Dados}
      />
    </Container>
  );
}
