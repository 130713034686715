import styled from "styled-components";

export const Button = styled.button`
  
  border: 2px solid black;
  border-radius: 4px;
  position: relative;
  margin-top: 5%;
  width: 7rem;
  height: 2rem;
`

export const FormRegistrar = styled.form`
  text-align: left;
  color: white;
  width: 100%;
  height: 100%;
  padding: 40px;
  border: 2px solid white;
  border-radius: 8px;

  input, select {
    margin: 10px;
    border: 2px solid black;
    border-radius: 5px;
  }

  div{
    margin-top: 5px;
    font-size: 20px;
  }

  label{
    font-size: 20px;
  }

  input[type="number" i]{
    margin: 10px;
    margin-left: 35px;
    position: relative;
    width: 100px;
  }
  
  input[type="text" i]{
    width: 420px;
  }
  `
  
  export const DivForm = styled.div`
  width: 600px;
  height: 670px;
  border: 3px solid #23232e;
  border-radius: 8px;
  margin-top: 4%;
  background: rgb(35,35,46);
  background: linear-gradient(180deg, rgba(35,35,46,1) 0%, rgba(32,32,72,1) 73%, rgba(23,23,124,1) 100%);

  
`