import React from "react";
import { Container, Icon } from "./style";
import { useNavigate } from "react-router";

export default function ReturnArrow() {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate(-1)}>
      <Icon />
    </Container>
  );
}
