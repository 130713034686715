import React, { useState, useEffect } from "react";
import { Background, Img, Title, Input, Button, Form, BigTitle } from "./style";
import api from "../../services/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../../auth";

export default function Index() {
  const navigate = useNavigate();
  const [tela, setTela] =
    useState(0); /** 0 - login | 1 - cadastro | 2 - recuperação */
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [nome, setNome] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirmacao, setSenhaConfirmacao] = useState("");

  useEffect(() => {
    if (isAuthenticated()) navigate("/home");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  async function handleSubmit(e) {
    e.preventDefault();

    if (tela === 0) {
      if (email === "" || senha === "") {
        Swal.fire({
          title: "Alerta!",
          text: "Preencha todos os campos!",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--primary-color)",
          background: "var(--white-color)",
          color: "var(--black-color)",
        });
      } else {
        const data = {
          email: email,
          senha: senha,
        };
        const response = await api.post("/login", { data });
        if (response.data.error) {
          Swal.fire({
            title: "Alerta!",
            text: response.data.error,
            icon: "warning",
            confirmButtonText: "OK",
            confirmButtonColor: "var(--primary-color)",
            background: "var(--white-color)",
            color: "var(--foblacknt-color)",
          });
        } else {
          localStorage.setItem("token", response.data.token);
          navigate("/home");
          window.location.reload();
        }
      }
    } else if (tela === 1) {
      if (
        email === "" ||
        senha === "" ||
        senhaConfirmacao === "" ||
        nome === ""
      ) {
        Swal.fire({
          title: "Alerta!",
          text: "Preencha todos os campos!",
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--primary-color)",
          background: "var(--white-color)",
          color: "var(--black-color)",
        });
      } else {
        if (!checked) {
          Swal.fire({
            title: "Alerta!",
            text: "Necessário concordar com os termos!",
            icon: "warning",
            confirmButtonText: "OK",
            confirmButtonColor: "var(--primary-color)",
            background: "var(--white-color)",
            color: "var(--black-color)",
          });
        } else {
          if (senha !== senhaConfirmacao) {
            Swal.fire({
              title: "Alerta!",
              text: "As senhas devem ser iguais!",
              icon: "warning",
              confirmButtonText: "OK",
              confirmButtonColor: "var(--primary-color)",
              background: "var(--white-color)",
              color: "var(--black-color)",
            });
          } else {
            const data = {
              email: email,
              senha: senha,
              nome: nome,
            };
            const response = await api.post("/register", { data });
            if (response.data.error) {
              Swal.fire({
                title: "Alerta!",
                text: response.data.error,
                icon: "warning",
                confirmButtonText: "OK",
                confirmButtonColor: "var(--primary-color)",
                background: "var(--white-color)",
                color: "var(--black-color)",
              });
            } else {
              localStorage.setItem("token", response.data.token);
              Swal.fire({
                title: "Sucesso!",
                text: response.data.success,
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "var(--primary-color)",
                background: "var(--white-color)",
                color: "var(--black-color)",
              });
              setTimeout(() => {
                navigate("/home");
                window.location.reload();
              }, 2000);
            }
          }
        }
      }
    } else if (tela === 2) {
      const data = {
        email: email,
      };
      const response = await api.post("/recover", { data });
      if (response.data.error) {
        Swal.fire({
          title: "Alerta!",
          text: response.data.error,
          icon: "warning",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--primary-color)",
          background: "var(--white-color)",
          color: "var(--black-color)",
        });
      } else {
        Swal.fire({
          title: "Sucesso!",
          text: response.data.success,
          icon: "success",
          confirmButtonText: "OK",
          confirmButtonColor: "var(--primary-color)",
          background: "var(--white-color)",
          color: "var(--black-color)",
        });
        clear();
        setTela(0);
      }
    }
  }

  /**
   * Limpa os inputs
   */
  function clear() {
    setEmail("");
    setNome("");
    setSenha("");
    setSenhaConfirmacao("");
    setChecked(false);
  }

  return (
    <Background>
      <Img alt="fundo" />
      <Form onSubmit={handleSubmit}>
        <BigTitle>Aluno Saudável</BigTitle>
        <Title>Login</Title>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <Input
          type="password"
          placeholder="Senha"
          value={senha}
          onChange={(e) => {
            setSenha(e.target.value);
          }}
        />
        <Button>Entrar</Button>
      </Form>
    </Background>
  );
}
