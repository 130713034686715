import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import api from "../../services/api";
import { HiOutlineExternalLink } from "react-icons/hi";
import { useNavigate } from "react-router";
import {
  Body,
  Title,
  Date,
  Table,
  RemoveButton,
  SelectedFiltersContainer,
  FiltersContainer,
  Filter,
  FilterButton,
  FilterBox,
  FilterOption,
  SelectedFilters,
  Arrow,
  SubFilterBox,
  ScrollTable,
} from "./style";

export default function Consultar() {
  const [Coletas, setColetas] = useState([]);
  const [config, setConfig] = useState(null);
  const [DataInicio, setDataInicio] = useState("");
  const [Filtros, setFiltros] = useState("");
  const [SelectedIndex, setSelectedIndex] = useState(-1);
  const [SelectedSubIndex, setSelectedSubIndex] = useState(-1);
  const navigate = useNavigate();
  const MenuFiltro = [
    {
      button: "Pressão",
      key: "pressao",
      options: [
        { key: "Pressão Normal", value: "Pressão Normal" },
        { key: "Pré Hipertensão", value: "Pré Hipertensão" },
        { key: "Hipertensão", value: "Hipertensão" },
      ],
    },
    {
      button: "Peso",
      key: "resultado_imc",
      options: [
        { key: "Muito abaixo do peso", value: "Muito abaixo do peso" },
        { key: "Abaixo do peso", value: "Abaixo do peso" },
        { key: "Peso Normal", value: "Abaixo do peso" },
        { key: "Acima do peso", value: "Acima do peso" },
        { key: "Obesidade I", value: "Obesidade I" },
        { key: "Obesidade II", value: "Obesidade II" },
        { key: "Obesidade III", value: "Obesidade III" },
      ],
    },
    {
      button: "Alimentos",
      sub_button: [
        {
          key: "consumo_agua",
          value: "Água",
          sub_options: [
            { key: "Sim", value: "Sim" },
            { key: "Não", value: "Não" },
          ],
        },
        {
          key: "leite_resultado",
          value: "Leite",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "carne_resultado",
          value: "Carne",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "oleo_resultado",
          value: "Óleo",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "petisco_resultado",
          value: "Petiscos",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "cereal_resultado",
          value: "Cereal",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "legume_resultado",
          value: "Legumes",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "hortalicas_resultado",
          value: "Hortaliças",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "sobremesa_resultado",
          value: "Sobremesas",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "diet_resultado",
          value: "Diets",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
        {
          key: "bebida_resultado",
          value: "Bebidas",
          sub_options: [
            { key: "A", value: "Apto" },
            { key: "I", value: "Inapto" },
          ],
        },
      ],
    },
    {
      button: "Gênero",
      key: "genero",
      options: [
        { key: "Feminino", value: "Feminino" },
        { key: "Masculino", value: "Masculino" },
      ],
    },
    {
      button: "Turno",
      key: "periodo",
      options: [
        { key: "Manhã", value: "Manhã" },
        { key: "Tarde", value: "Tarde" },
        { key: "Noite", value: "Noite" },
      ],
    },
    {
      button: "Diabetes",
      key: "dextro",
      options: [
        { key: "Normal", value: "Sem diabetes" },
        { key: "Diabetes", value: "Com Diabetes" },
      ],
    },
  ];

  async function handlePdf(e) {
    e.preventDefault();
    Swal.fire({
      title: "Você tem certeza?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "var(--primary-color)",
      cancelButtonColor: "var(--secundary-color)",
      confirmButtonText: "Gerar PDF",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("PDF sendo criado", "", "success");
      }
    });
  }
  const GetConfig = () => {
    api
      .get(`/config`)
      .then((response) => {
        setConfig(response.data.config[0]);
      })
      .catch((error) => alert(error));
  };

  useEffect(() => {
    api
      .post(`/coletas`, Filtros)
      .then((response) => {
        setColetas(response.data.coletas);
      })
      .catch((error) => alert(error));
  }, [Filtros]);

  useEffect(() => {
    let Hoje = new window.Date("2022/05/02");
    if (Hoje.getMonth() <= 5) {
      setDataInicio(`${Hoje.getFullYear()}-01-01`);
    } else {
      setDataInicio(`${Hoje.getFullYear()}-06-01`);
    }
  }, []);

  function getAge(dateString) {
    const today = new Date();
    const [dia, mes, ano] = dateString.split("/");
    var birthDate = new Date(`${ano}-${mes}-${dia}`);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() + 1 - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  async function AddFilter(value, key) {
    setFiltros({
      ...Filtros,
      [key]: Filtros[key] ? `${Filtros[key]};${value}` : value,
    });
  }

  async function RemoveFilter(value, key) {
    if (Filtros[key].includes(";")) {
      setFiltros({
        ...Filtros,
        [key]: `${Filtros[key]
          .replace(value, "")
          .substring(0, 1)
          .replace(";", "")}${Filtros[key]
          .replace(value, "")
          .substring(1, Filtros[key].replace(value, "").length - 1)
          .replace(";;", ";")}${Filtros[key]
          .replace(value, "")
          .substring(
            Filtros[key].replace(value, "").length - 1,
            Filtros[key].replace(value, "").length
          )
          .replace(";", "")}`,
      });
    } else {
      setFiltros((current) => {
        const copy = { ...current };
        delete copy[key];
        return copy;
      });
    }
  }

  return (
    <Body>
      <Title>Consulta de Coletas</Title>
      <FiltersContainer
        tabIndex="0"
        onBlur={() => {
          setSelectedIndex(-1);
          setSelectedSubIndex(-1);
        }}
      >
        {MenuFiltro.map((m, i) => (
          <Filter key={i}>
            <FilterButton
              onClick={() =>
                SelectedIndex === i ? setSelectedIndex(-1) : setSelectedIndex(i)
              }
            >
              {m.button}
              <Arrow open={SelectedIndex === i} />
            </FilterButton>
            <FilterBox show={SelectedIndex === i}>
              {m.options ? (
                m.options.map((o, i2) => (
                  <FilterOption
                    key={i2}
                    onClick={() => {
                      Filtros[m.key] && Filtros[m.key].includes(o.key)
                        ? RemoveFilter(o.key, m.key)
                        : AddFilter(o.key, m.key);
                    }}
                    selected={
                      Filtros[m.key] &&
                      Filtros[m.key].split(";").includes(o.key)
                    }
                  >
                    {o.value}
                  </FilterOption>
                ))
              ) : (
                <>
                  {m.sub_button.map((sb, i3) => (
                    <React.Fragment key={i3}>
                      <FilterButton
                        key={i3}
                        onClick={() =>
                          SelectedSubIndex === i3
                            ? setSelectedSubIndex(-1)
                            : setSelectedSubIndex(i3)
                        }
                      >
                        {sb.value}
                        <Arrow open={SelectedSubIndex === i3} />
                      </FilterButton>
                      <SubFilterBox show={SelectedSubIndex === i3}>
                        {sb.sub_options.map((so, i4) => (
                          <FilterOption
                            key={i4}
                            onClick={() => {
                              Filtros[sb.key] &&
                              Filtros[sb.key].includes(so.key)
                                ? RemoveFilter(so.key, sb.key)
                                : AddFilter(so.key, sb.key);
                            }}
                            selected={
                              Filtros[sb.key] &&
                              Filtros[sb.key].split(";").includes(so.key)
                            }
                          >
                            {so.value}
                          </FilterOption>
                        ))}
                      </SubFilterBox>
                    </React.Fragment>
                  ))}
                </>
              )}
            </FilterBox>
          </Filter>
        ))}
      </FiltersContainer>
      <SelectedFiltersContainer>
        {Object.entries(Filtros).map(([k, cs], i) => (
          <React.Fragment key={i}>
            {cs.includes(";") ? (
              <>
                {cs.split(";").map((c, i) => (
                  <SelectedFilters key={i}>
                    {k
                      .replaceAll("_", " ")
                      .replace(/(^\w{1})|(\s+\w{1})/g, (letra) =>
                        letra.toUpperCase()
                      )}
                    :{c}
                    <RemoveButton onClick={() => RemoveFilter(c, k)} />
                  </SelectedFilters>
                ))}
              </>
            ) : (
              <SelectedFilters>
                {k
                  .replaceAll("_", " ")
                  .replace(/(^\w{1})|(\s+\w{1})/g, (letra) =>
                    letra.toUpperCase()
                  )}
                : {cs}
                <RemoveButton onClick={() => RemoveFilter(cs, k)} />
              </SelectedFilters>
            )}
          </React.Fragment>
        ))}
      </SelectedFiltersContainer>
      <ScrollTable>
        <Table className="table table-hover">
          <thead>
            <tr>
              <th>RM</th>
              <th>Nome</th>
              <th>Idade</th>
              <th>Gênero</th>
              <th>Turno</th>
              <th>Pressão</th>
              <th>Peso</th>
              <th>Diabetes</th>
              <th>Alimentos</th>
            </tr>
          </thead>
          <tbody>
            {Coletas !== null ? (
              Coletas.map((aluno) => (
                <tr
                  key={aluno.rm}
                  onClick={() => navigate(`../coleta/${aluno.rm}`)}
                >
                  <td>{aluno.rm}</td>
                  <td className="aluno">{aluno.nome.toUpperCase()}</td>
                  <td>{aluno.idade}</td>
                  <td>{aluno.genero.toUpperCase()}</td>
                  <td>{aluno.periodo}</td>
                  <td>{aluno.pressao.replace("Pressão ", "")}</td>
                  <td>{aluno.resultado_imc}</td>
                  <td>{aluno.dextro}</td>
                  <td>
                    <HiOutlineExternalLink size={20} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12">Nenhum aluno encontrado.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </ScrollTable>
    </Body>
  );
}
