import styled from "styled-components";

export const Container = styled.div`
  padding-top: 80px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  overflow: auto;
`;

export const DivButton = styled.div`
  width: 32%;
  margin-left: auto;
  margin-right: auto;

  button {
    background-color: #23232e;
    color: #fff5ee;
    margin: 30px;
    border-radius: 20px;
    width: 140px;
  }

  button:disabled {
    display: none;
  }

  @media (max-width: 1366px) {
  }
`;

export const Pesquisa = styled.div`
  padding: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: start;
  align-items: center;
  min-height: 3vh;
  width: 100%;
  position: absolute;
  top: -60px;
  left: 0;
  z-index: -1;
`;

export const TabelaAll = styled.table`
  margin-top: 50px;
  background-color: #eeeeee;
  border-radius: 10px;
  margin-left: 5px;
  width: 98%;
  border-collapse: collapse;
  border-color: #fff5ee;
  color: black;
  border-top-left-radius: 0px;
  min-height: 100px;
  z-index: 2;
  position: relative;

  tbody tr {
    &:hover {
      filter: brightness(90%);
      box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
      transform: translateY(-1px);
      background: #f3f3f3;
    }
  }

  td {
    border-top: 1px solid white;
    padding: 5px;
    text-align: center;
  }

  th {
    text-align: center;
  }

  @media (max-width: 1366px) {
    td {
      border-top: 1px solid white;
      padding: 1px;
      text-align: center;
    }
  }
`;
