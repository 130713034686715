import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Background,
  Page,
  Subpage,
  Images,
  Content,
  Title,
  Table,
  Breaker,
  Assinaturas,
  Legenda,
} from "./style";
import alunoSaudavel from "../../assets/Images/aluno_saudavel.jpg";
import fiec from "../../assets/Images/fiec.png";
import prefeitura from "../../assets/Images/prefeitura.jpg";
import PdfButtons from "../../components/PdfButtons";
import api from "../../services/api";
import Swal from "sweetalert2";
import Auth from "../../auth";
import formatDateString from "../../services/formatDate";
import formatDateTimeString from "../../services/formatDateTimeString";
import { Highlight } from "../PdfAluno/style";

function resultadoCor(resultado) {
  if (resultado === "A") {
    return "rgba(0, 0, 255, 0.63)";
  }
  if (resultado === "PA") {
    return "#ff9100";
  }
  if (resultado === "I") {
    return "rgb(255, 0, 0)";
  }
}

export default function PdfTodosalunos() {
  const [Dados, setDados] = useState([]);
  const [alunos, setalunos] = useState([]);
  const [element, setelement] = useState([]);
  const [data_emissao, setData_emissao] = useState("");
  const [rm, setRm] = useState("");
  const location = useLocation();
  const ref = useRef();

  useEffect(() => {
    api
      .get(`/todosalunos`)
      .then((response) => {
        if (response.data.error)
          return Swal.fire({
            title: "Atenção!",
            text: response.data.error,
            icon: "warning",
            confirmButtonColor: "var(--blue-color)",
          });
        setDados(response.data.response);
        setData_emissao(response.data.dataPDF);
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: "Atenção!",
          text: "Não foi possível consultar!",
          icon: "warning",
          confirmButtonColor: "var(--blue-color)",
        });
      });
  }, [location.state, rm]);

  useEffect(() => {
    setRm(location?.state?.rm);
  }, [location?.state?.rm]);

  return (
    <Background>
      <PdfButtons />
      <Page>
        <Subpage>
          <Breaker ref={ref}>
            {Dados?.map((element, index) => (
              <React.Fragment key={index}>
                <Images>
                  <img src={fiec} alt="erro" />
                  <img src={alunoSaudavel} alt="erro" />
                  <img src={prefeitura} alt="erro" />
                </Images>
                <br />
                <Content>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>RM:</b> {element?.rm}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Nome:</b> {element?.nome}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Gênero:</b> {element?.genero}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Nascimento:</b>{" "}
                          {formatDateString(element?.data_nascimento)}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Curso:</b> {element?.curso}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Periodo:</b> {element?.periodo}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>PA Min:</b> {element?.pressao_minima}{" "}
                        </td>
                        <td className="td-centro">
                          <b>PA Max:</b> {element?.pressao_maxima}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Hipertensão arterial:</b> {element?.pressao}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Dextro (mg/l):</b> {element?.dextro_mgl}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Diabetes:</b> {element?.dextro}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Tipagem sanguínea:</b> {element?.tipo_sanguineo}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Peso (kg):</b> {element?.peso_kg}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Estatura (m):</b> {element?.estatura_metros}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Utiliza Óculos:</b> {element?.oculos}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Visual (Direita):</b>{" "}
                          {element?.acuidade_visual_direita}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Visual (Esquerda):</b>{" "}
                          {element?.acuidade_visual_esquerda}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Convulsões:</b> {element?.convulsoes}{" "}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <b>Alergias:</b> {element?.alergias}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Doenças Crônicas:</b>{" "}
                          {element?.doenca_cronica_tratamento}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Visual (Esquerda):</b>{" "}
                          {element?.acuidade_visual_esquerda}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Convulsões:</b> {element?.convulsoes}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Alergias:</b> {element?.alergias}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Doenças Crônicas:</b>{" "}
                          {element?.doenca_cronica_tratamento}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>IMC:</b> {element?.imc}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <center>
                    <b>Classificação: </b>
                    <Highlight>{element?.resultado_imc} </Highlight>
                  </center>
                  <Table>
                    <tr>
                      <td>
                        <b>Circ. Abdominal (cm):</b>{" "}
                        {element?.circ_abdominal_cm}{" "}
                      </td>
                    </tr>
                  </Table>
                  <center>
                    <b>Risco Cardíaco Segundo Circ. Abdominal: </b>
                    <Highlight>{element?.res_circ_abdominal} </Highlight>
                  </center>
                  <br />
                  <Title>Avaliação Nutricional</Title>
                  <p style={{ fontSize: "12px" }}>
                    {" "}
                    Considerando as recomendações da OMS (Organização Mundial de
                    Saúde) para a promoção da saúde e prevenção de doenças.
                  </p>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>Prática de atividade física:</b>{" "}
                          {element?.atividade_fisica}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Ingerir 8 ou mais copos de água por dia:</b>{" "}
                          {element?.consumo_agua}{" "}
                        </td>
                        <td>
                          <b>Quantidade:</b> {element?.qtos_copos_agua}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>
                            Fazer dieta ou suplementação COM
                            conhecimento/orientação nutricional:
                          </b>{" "}
                          {element?.suplementacao_alimentar_uili}{" "}
                        </td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>
                          <b>Fazer 4 ou mais refeições ao dia:</b>{" "}
                          {element?.qtas_refeicoes_dia}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Quantas refeições em casa:</b>{" "}
                          {element?.qtas_refeicoes_em_casa}{" "}
                        </td>
                        <td>
                          <b>Refeições realizadas fora de casa:</b>{" "}
                          {element?.qtas_refeicoes_fora}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b>Adicionar sal às refeições já prontas:</b>{" "}
                          {element?.adiciona_sal_dps_pronto}{" "}
                        </td>
                        <td>
                          <b>Adicionar açúcar aos líquidos ingeridos:</b>{" "}
                          {element?.adiciona_acucar_liquidos}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Title>Bioimpedância</Title>
                  <Table>
                    <tbody>
                      <tr>
                        <td>
                          <b>% Gordura:</b> {element?.bioimpedancia_de_gordura}{" "}
                        </td>
                        <td className="td-centro">
                          <b>Massa Magra (kg):</b>{" "}
                          {element?.bioimpedancia_massa_magra_kg}{" "}
                        </td>
                        <td className="td-direita">
                          <b>Massa Gorda (kg):</b>{" "}
                          {element?.bioimpedancia_massa_gorda_kg}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <br />
                  {/* <div id="bio">
                    <Title>Avaliação Nutricional</Title>
                  </div>
                  <p style={{ fontSize: "12px" }}>
                    Considerando as recomendações da OMS (Organização Mundial de
                    Saúde) para a promoção da saúde e prevenção de doenças.
                  </p> */}
                  <p style={{ fontSize: "14px" }}>
                    <b>
                      <u>Seus hábitos em relação aos itens abaixo estão:</u>
                    </b>
                  </p>
                  <Table>
                    <tr>
                      <span style={{ color: "rgba(0, 0, 255, 0.63)" }}>
                        {" "}
                        A – Adequado{" "}
                      </span>
                      /
                      <span style={{ color: "#ff9100" }}>
                        {" "}
                        PA – Parcialmente Adequado{" "}
                      </span>
                      /
                      <span style={{ color: "rgb(255, 0, 0)" }}>
                        {" "}
                        I – Inadequado{" "}
                      </span>
                    </tr>
                  </Table>
                  <b>Frequência de Consumo:</b>
                  <Table>
                    <thead>
                      <th>Grupo</th>
                      <th style={{ textAlign: "center", width: "1%" }}>
                        Class.
                      </th>
                    </thead>
                    <tbody className="tbody-ultimo-tabela">
                      <tr>
                        <td>
                          Leite e derivados- ex: leite, iogurte, queijo, etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.leite_resultado),
                          }}
                        >
                          {" "}
                          {element?.leite_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Carne e ovos- ex: carne de boi, ovo, peixe, linguiça,
                          etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.carne_resultado),
                          }}
                        >
                          {" "}
                          {element?.carne_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Óleos- ex: azeite, óleo, margarina, maionese</td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.oleo_resultado),
                          }}
                        >
                          {" "}
                          {element?.oleo_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Petiscos e enlatados- ex: milho, salgadinhos, etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.petisco_resultado),
                          }}
                        >
                          {" "}
                          {element?.petisco_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Cereais, massas e tubérculos- Ex: arroz, macarrão,
                          batata, etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.cereal_resultado),
                          }}
                        >
                          {" "}
                          {element?.cereal_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Leguminosas- ex: feijão, vagem, ervilha, soja, etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.legume_resultado),
                          }}
                        >
                          {" "}
                          {element?.legume_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Hortaliças e frutas- ex: alface, tomate, banana, maçã,
                          etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.hortalicas_resultado),
                          }}
                        >
                          {" "}
                          {element?.hortalicas_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Sobremesas e doces- ex: sorvete, chocolate, etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.sobremesa_resultado),
                          }}
                        >
                          {" "}
                          {element?.sobremesa_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>Bebidas- ex: café, suco, refrigerante, etc.</td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.bebida_resultado),
                          }}
                        >
                          {" "}
                          {element?.bebida_resultado}{" "}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Produtos diet e light- ex: adoçante, margarina,
                          refrigerante, etc.
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: resultadoCor(element?.diet_resultado),
                          }}
                        >
                          {element?.diet_resultado}{" "}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Legenda>
                    *Informações fornecidas e preenchidas pelo próprio aluno em:{" "}
                    {formatDateTimeString(element?.data_coleta)}
                  </Legenda>
                </Content>
                {index !== Dados.length - 1 && (
                  <div style={{ pageBreakAfter: "always" }}></div>
                )}
                <br />
                <br />
              </React.Fragment>
            ))}
          </Breaker>
        </Subpage>
      </Page>
    </Background>
  );
}
