import styled from "styled-components";


export const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;

  background: var(--background-color);
`;

export const Img = styled.img`
  width: 50%;
  height: 100%;

  background: center center;
  background-size: cover;

  @media screen and (max-width: 1320px) { /* Tablet styles */
    display: none;
  } 
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 30%;
  margin: 0 200px;
  max-height: 700px;

  background: var(--white-color);
  transition: max-height 4s ease-in-out;

  border: 1px solid var(--black-color);
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  
  @media screen and (max-width: 1320px) { /* Tablet styles  */
    width: 70%;
    margin: 0px;
  } 

  @media screen and (max-width: 480px) { /* Mobile styles  */
    width: 80%;
    margin: 0px;
  }
   
`;

export const Menu = styled.div`
  width: 50%;
  display: grid;
  grid-template-areas: "l r";
  text-align: center;
  justify-items: center;
  margin-top: 5%;
`;

export const Login = styled.div`
  border-bottom: ${(props) =>
    props.tela === 0
      ? "2px solid var(--black-color)"
      : "2px solid var(--white-color)"};
  color: ${(props) =>
    props.tela === 0 ? "var(--black-color)" : "var(--black-color)"};
  width: 35%;
  grid-area: l;
  cursor: pointer;

  @media screen and (max-width: 768px) { /* Tablet styles  */
    width: 80%;
  }  
`;

export const Register = styled.div`
  border-bottom: ${(props) =>
    props.tela === 1
      ? "2px solid var(--black-color)"
      : "2px solid var(--white-color)"};
  color: ${(props) =>
    props.tela === 1 ? "var(--black-color)" : "var(--black-color)"};
  width: 48%;
  grid-area: r;
  cursor: pointer;

  @media screen and (max-width: 820px) { /* Ajuste para celular */
  width: 90%;
  } 
`;

export const EsqueciSenha = styled.div`
  margin-top: 3%;
  color: var(--font-color);
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline var(--secondary-color);
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled.h1`
  color: var(--font-color);
  line-height: 1.43;
  font-size: 20px;
  font-weight: 500;
  margin-top: 10%;
`;

export const Input = styled.input`
  width: 65%;

  margin-top: 8%;
  padding: 0.5rem;

  background: none;
  color: var(--font-color);

  border: 2px solid var(--font-color);
  border-radius: 0.3rem;
  border-bottom: 2px solid #ccc; 
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;

  ::placeholder {
    color: var(--font-color);
  }

  @media (max-width: 500px) {
    width: 80%;
  }
`;

export const Button = styled.button`
  border-radius: 0.3rem;
  cursor: pointer;
  text-transform: uppercase;
  border: none;
  color: none;
  color: var(--white-color);
  background: var(--background-color);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  color: var(--black-color);
  margin-top: 10%;
  font-size: 0.9em;
  align-items: center;

  a {
    margin-top: 1.2%;
    border-bottom: 1px solid #ccc; 
    text-decoration: none;
    color: var(--black-color);
  }
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--black-color);
  width: 15px;
  height: 15px;
  border-radius: 15%; 
  margin-right:   10px;
  cursor: pointer;
`;
