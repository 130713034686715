import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: space-around;
  height: 70px;
  padding: 0.5rem;
  background-color: var(--background-color);

  @media screen and (max-width: 1366px) {
    height: 50px;
  }
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;

  height: 100%;
  padding: 0 1rem;

  font-size: 1.1rem;
  letter-spacing: 1.5px;
  text-decoration: none;
  color: var(--white-color);
  cursor: pointer;

  :hover {
    color: var(--primary-color);
    opacity: 0.3s;
  }
  &.active {
    color: var(--primary-color);
  }
`;

export const Img = styled.img`
  @media screen and (max-width: 790px) {
    position: absolute;
    top: 10;
    left: 10px;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: var(--white-color);

  @media screen and (max-width: 790px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  margin-right: 24px;

  @media screen and (max-width: 790px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 790px) {
    display: none;
  }
`;

export const NavBtnLink = styled(Link)`
  padding: 10px 22px;

  background: var(--secundary-color);
  border-radius: 25px;
  border: none;
  outline: none;

  color: var(--white-color);
  letter-spacing: 1.5px;
  cursor: pointer;

  transition: all 0.2s ease-in-out;
  text-decoration: none;

  :hover {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    background: var(--white-color);
    color: var(--black-color);
  }

  @media screen and (max-width: 1366px) {
    padding: 6px 20px;
  }
`;
