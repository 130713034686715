import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: 90vh;

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
  }

  @media screen and (max-width: 790px) {
    /* Mobile styles  */
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  width: 40%;
  height: 70%;
  margin: 15px 10px;
  padding: 10px;

  border-radius: 15px;

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    width: 80%;
    height: 80%;
    margin: 0 50px;
  }

  @media screen and (max-width: 790px) {
    /* Mobile styles  */
    width: 80%;
    height: 80%;
    margin: 0 50px;
  }
`;

export const Title = styled.h1`
  margin: 50px 0 0 0;
  letter-spacing: 1px;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  height: 90%;
  padding: 10px;
`;

export const Button = styled.button`
  width: 150px;
  height: 50px;
  margin: 50px 0 0 0;
  background-color: var(--secundary-color);
  border-radius: 25px;
  border: none;
  outline: none;
  color: var(--white-color);
  letter-spacing: 1.5px;
  cursor: pointer;
  transition: transform 0.2s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:enabled {
    transform: scale(1.1);
  }

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    height: 50px;
  }

  @media screen and (max-width: 790px) {
    /* Mobile styles  */
    height: 50px;
  }
`;

export const IconContainer = styled.div`
  height: fit-content;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  flex-direction: column;

  img {
    width: 150px;
    height: 150px;
    object-fit: fill;
  }

  .trash-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    color: red;
    width: 40px;
    height: 40px;
  }
`;
