import styled from "styled-components";

export const Background = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 70px);
  height: 100%;
  background-color: var(--white-color);
  padding: 30px 0;

  @media (min-height: 500px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  height: 60%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #edeee9;
  border-radius: 25px;

  @media screen and (max-width: 1400px) {
    width: 90%;
  }

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    height: fit-content;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 500px;
  background: center center;
  background-size: cover;
  object-fit: contain;

  @media screen and (max-width: 1200px) {
    height: 400px;
  }

  @media screen and (max-width: 550px) {
    height: 300px;
  }

  @media screen and (max-width: 450px) {
    height: 200px;
  }
`;

export const P = styled.p`
  font-size: 1.6rem;
  padding: 30px;
  text-align: justify;

  @media screen and (max-width: 1320px) {
    /* Tablet styles  */
    font-size: 1.5rem;
  }

  @media screen and (max-width: 390px) {
    /* Mobile styles  */
    font-size: 1.3rem;
  }
`;
